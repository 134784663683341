<template>
  <div class="align window-height-partner">
    <div class="section-title fade" v-scrollanimation>
      <h2 class="section-header">Our Partners</h2>
    </div>
    <div class="items moveUp" v-scrollanimation>
      <div class="item" v-for="(company, index) in companies" :key="index">
        <q-img
          :src="require(`../../assets/partners/${company}.png`)"
          class="logo"
          fit="contain"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  setup() {
    const companies = ref([
      "amazon",
      "axis",
      "dali",
      "hikvision",
      "nvidia"
    ]);

    return {
      companies,
    };
  },
};
</script>

<style scoped>
@import "../../styles/globalStyles.scss";

.items {
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.item {
  width: 15vw;
  min-width: 130px;
  max-width: 270px;
  flex: auto;
  position: relative;
  margin: 10px;
  display: flex;
  justify-content: center;
}
.logo {
  max-width: min(80%, 150px);
  display: inline-block;
  padding: 15px 0;
  filter: grayscale(100);
  height: auto;
}
.logo:hover {
  filter: none;
  transform: scale(1.1);
}
</style>
